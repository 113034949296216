import { useEffect, useState } from 'react';
import * as personService from './services/personService';

const Filter = ({ filter, handleFilterInputChange }) => {
    return (
        <div>
            Filter with:{' '}
            <input value={filter} onChange={handleFilterInputChange} />
        </div>
    );
};

const Contact = ({ person, deletePerson }) => {
    return (
        <div>
            {person.name} {person.number}{' '}
            <button onClick={() => deletePerson(person.id)}>Delete</button>
        </div>
    );
};

const ContactList = ({ persons, filter, deletePerson }) => {
    return (
        <div>
            <h3>Numbers</h3>
            {persons
                .filter((person) =>
                    person.name.toLowerCase().includes(filter.toLowerCase())
                )
                .map((person) => (
                    <Contact
                        person={person}
                        deletePerson={deletePerson}
                        key={person.id}
                    />
                ))}
        </div>
    );
};

const AddContactForm = ({
    newName,
    newNumber,
    handleAddContactFormSubmit,
    handleNameInputChange,
    handleNumberInputChange,
}) => {
    return (
        <div>
            <h3>Add a Contact</h3>
            <form onSubmit={handleAddContactFormSubmit}>
                <div>
                    name:{' '}
                    <input value={newName} onChange={handleNameInputChange} />
                </div>
                <div>
                    number:{' '}
                    <input
                        value={newNumber}
                        onChange={handleNumberInputChange}
                    />
                </div>
                <div>
                    <button type='submit'>add</button>
                </div>
            </form>
        </div>
    );
};

const Notification = ({ text, isError }) => {
    //does not function as intended, if a notification is "sent"
    //while another one is still up
    //but this is how the exercise and material instructed to do it
    return text ? (
        <div className={`notification ${isError ? 'error' : 'info'}`}>
            {text}
        </div>
    ) : (
        <></>
    );
};

const App = () => {
    const [persons, setPersons] = useState([]);
    const [newName, setNewName] = useState('');
    const [newNumber, setNewNumber] = useState('');
    const [filter, setFilter] = useState('');
    const [notificationText, setNotificationText] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        personService.getPersons().then((p) => setPersons(p));
    }, []);

    const handleNameInputChange = (event) => {
        setNewName(event.target.value);
    };

    const handleNumberInputChange = (event) => {
        setNewNumber(event.target.value);
    };

    const handleFilterInputChange = (event) => {
        setFilter(event.target.value);
    };

    const notify = (msg) => {
        setNotificationText(msg);
        setIsError(false);
        setTimeout(() => setNotificationText(''), 5000);
    };

    const showError = (msg) => {
        setNotificationText(msg);
        setIsError(true);
        setTimeout(() => setNotificationText(''), 5000);
    };

    const handleAddContactFormSubmit = (event) => {
        event.preventDefault();

        const existingPerson = persons.find(
            (person) => person.name === newName
        );

        if (!existingPerson) {
            personService
                .addPerson(
                    {
                        name: newName,
                        number: newNumber,
                    },
                    showError
                )
                .then((newPerson) => {
                    if (!newPerson) return;
                    
                    setPersons(persons.concat(newPerson));
                    notify(`Added ${newPerson.name}`);
                    setNewName('');
                    setNewNumber('');
                });

            return;
        } else if (
            !window.confirm(
                `${existingPerson.name} is already in the phonebook, replace number?`
            )
        ) {
            return;
        }

        personService
            .updatePerson(
                {
                    ...existingPerson,
                    number: newNumber,
                },
                showError
            )
            .then((newPerson) => {
                if (!newPerson) return;

                setPersons(
                    persons.map((p) => (p.id === newPerson.id ? newPerson : p))
                );
                notify(`Changed ${newPerson.name}'s number`);
                setNewName('');
                setNewNumber('');
            });
    };

    const deletePerson = (id) => {
        if (!window.confirm('Delete contact?')) return;

        personService.deletePerson(id).then(() => {
            setPersons(persons.filter((p) => p.id !== id));
        });
    };

    return (
        <div>
            <h2>Phonebook</h2>
            <Notification text={notificationText} isError={isError} />
            <Filter
                filter={filter}
                handleFilterInputChange={handleFilterInputChange}
            />
            <AddContactForm
                newName={newName}
                newNumber={newNumber}
                handleAddContactFormSubmit={handleAddContactFormSubmit}
                handleNameInputChange={handleNameInputChange}
                handleNumberInputChange={handleNumberInputChange}
            />
            <ContactList
                persons={persons}
                filter={filter}
                deletePerson={deletePerson}
            />
        </div>
    );
};

export default App;
