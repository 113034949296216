import axios from 'axios';

const baseUrl = '/api/persons';

export const addPerson = async (newPerson, showError) => {
    return axios
        .post(baseUrl, newPerson)
        .then((res) => res.data)
        .catch((error) => {
            const message = error.response?.data?.error;

            if (showError) showError(message || 'adding person failed');
            if (!message) console.error(error);
        });
};

export const getPersons = async () => {
    return axios
        .get(baseUrl)
        .then((res) => res.data)
        .catch((error) => {
            console.error(error);
            return [];
        });
};

export const deletePerson = async (id) => {
    return axios
        .delete(`${baseUrl}/${id}`)
        .catch((error) => console.error(error));
};

export const updatePerson = async (newPerson, showError) => {
    return axios
        .put(`${baseUrl}/${newPerson.id}`, newPerson)
        .then((res) => res.data)
        .catch((error) => {
            const message = error.response?.data?.error;

            if (showError) showError(message || 'updating person failed');
            if (!message) console.error(error);
        });
};
